
@-webkit-keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

body {
  /* layout settings */
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; 
  background-color: black;
  /* hide scrollbar */
  -ms-overflow-style: none;  /* Hide scrollbar IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

h1 {
  font-size: 3vw;
  font-weight: bolder;
}
h2 {
  font-size: 4vw;
  font-weight: 300;
}

h3 {
  font-size: 3vw;
  font-weight: 300;
}

h4 {
  font-size: 2vw;
  font-weight: 300;
}

input,textarea,button{
  border-style: solid;
  border-color: white;
  background-color: rgba(0,0,0,0.5);
  color: white;
}

iframe {
  width: 60vw;
  height: 30vw;
}

svg {
  margin: 10px;
  max-width: 64px;
  height: auto;
  transition: all 0.50s;
}
/* mouse over animation */
svg:hover {
    filter: brightness(75%);
    -webkit-filter: brightness(75%);
    -moz-filter: brightness(75%);
    -o-filter: brightness(75%);
    -ms-filter: brightness(75%);
    transition: all 0.50s;
    margin: 0px;
}

.App {
  /*Default text settings*/
  text-align: center;
  font-size: calc(10px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 300;
  font-family: 'Poppins', sans-serif;

}

#Main {
  z-index: 1;
  position: absolute;
  margin: 0px auto;
  width: 100%;
  padding: 0px;
}

#Welcome {
  -webkit-animation: fadeInAnimation ease 3s;
          animation: fadeInAnimation ease 3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  color: white;
  justify-content: center;
  z-index: 1;
  margin-top: 20%;
  margin-bottom: 50%;
}

#Home, #Info, #Projects {
  background-color: white;
  width: 100%;
  margin: 100px;
  padding: 60px;
  margin-bottom: 40%;
}

#Info {
  margin-bottom: 5%;
}


#title{
  padding-left: 20px;
  color: white;
}

#Header {
  max-height: 100px;
  position: fixed;
  z-index: 100;
  top: 0;
  padding: 5px;
  background: rgba(0,0,0,.5);
  padding-left: 40px;
  padding-right: 40px;
}

#tsparticles {
  height: 3000px;
  width: 100%;
  z-index: 0;
  position: fixed;
  overflow-y: hidden;
}

#emailSubmit {
  transition: all 0.50s;
}

#emailSubmit:hover {
  background-color: white;
  color: black;
  transition: all 0.50s;
}


#Footer {
  z-index: 1;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  color: white;
  margin-top: 300px;
  padding: 60px;
  height: 100%;
}

#Form {
  color: white;
  margin: 80px;
}

#Header-logo{
  height: 64px;;
  min-height:100%;
}

.Header-button{
  border-style: solid;
  border-color: white;
  background-color: rgba(0,0,0,0.5);
  color: white;
  text-decoration: none;
  font-size: 2vw;
  padding: 15px;
  max-height: 70px;
  transition: all 0.50s;
}
.Header-button:hover{
  color: black;
  text-decoration: none;
  background-color: white;
  transition: all 0.50s;
}

.Header-link{
  color: white;
  text-decoration: none;
  font-size: 2vw;
  padding: 15px;
  max-height: 70px;
  transition: all 0.50s;

}

.Header-link:hover{
  color: black;
  text-decoration: none;
  transition: all 0.50s;
}

.no-outline:focus {
  outline: none;
}

.fade-in-section {
  opacity: 0;
  -webkit-transform: translateY(20vh);
          transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.project {
  padding: 30px;
  border: solid black 1px;
  transition: all 0.50s;
  height: 80vh;
}
.project:hover {
  padding-top: 5px;
  color: white;
  background-color: black;
  transition: all 0.50s;
}

.project-title{
  height: 10vh;
}
.project-img {
  margin: auto;
  border: solid 1px black;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 5vw;
    font-weight: bolder;
  }
  h2 {
    font-size: 7vw;
  }
  
  h3 {
    font-size: 5vw;
  }

  h4 {
    font-size: 4vw;
  }

  p {
    font-size: 3vw;
  }
  input, textarea {
    margin: 10px;
  }

  #emailSubmit {
    margin: 20px;
  }
  #Projects, #Info, #Home, #Form {
    margin: 0px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  #Welcome {
    margin-top: 100%;
    margin-bottom: 100%;
  }

  .skill-container {
    max-width: 20vw;
  }

}
